<template>
  <div>
    <!-- Start Header Area -->
    <HeaderThree class="personal-portfolio">
      <img slot="logo" :src="logo" alt="logo" />
    </HeaderThree>
    <!-- End Header Area -->
    <!-- Start Slider Area  -->
    <div class="rn-slider-area personal-portfolio-slider-area">
      <div
        class="justify-center slide slider_fixed_height slider-style-3 d-flex align-center bg_image bg_image--1 white-overlay-mobile"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-left inner">
                <span class="theme-gradient font-800"
                  ><strong>Student: Cody Squadroni</strong>
                </span>
                <h1 class="heading-title">
                  LDTE 5020<br /><span>Technology and the Mind</span>
                </h1>
                <p class="description">
                  Personal Learning Website
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Portfolio Area -->
    <div class="about-area pt--120 pb-120 bg_color--1">
      <div class="portfolio-sacousel-inner mb--55 mb_sm--0">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="text-center section-title mb--30 mb_sm--0">
                <span class="subtitle">My Work</span>
                <p class="description">
                  Below are my assignments, click the module for more details.
                </p>
              </div>
            </v-col>
          </v-row>
          <PortfolioFour />
        </v-container>
      </div>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import HeaderThree from "../../components/header/HeaderThree";
import PortfolioFour from "../../components/portfolio/PortfolioFour";
import Footer from "../../components/footer/FooterTwo";

export default {
  components: {
    HeaderThree,
    PortfolioFour,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
    };
  },
};
</script>

<style lang="scss"></style>
